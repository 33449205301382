/* REGION : VN & MY */
//import all svg files for ShopeeLogo
const reqSvgLogo = require.context(
  "src/Common/Assets/Icons/ShopeeLogo/",
  true,
  /\.svg$/
);
const svgFileLogoPaths = reqSvgLogo.keys();
//import all svg files for Socials
const reqSvgSocial = require.context(
  "src/Common/Assets/Icons/Socials/",
  true,
  /\.svg$/
);
const svgFileSocialPaths = reqSvgSocial.keys();
//import all svg files for App Stores
const reqSvgApp = require.context(
  "src/Common/Assets/Icons/AppStore/",
  true,
  /\.svg$/
);
const svgFileAppPaths = reqSvgApp.keys();

const reqPngIcons = require.context(
  "src/Common/Components/AppFooter/Assets/Icons/Png",
  true,
  /\.png$/
);
const pngFileIconsPaths = reqPngIcons.keys();

const reqPngVnIcons = require.context(
  "src/Common/Components/AppFooter/Assets/Icons/Vn",
  true,
  /\.png$/
);
const pngVnFileIconsPaths = reqPngVnIcons.keys();
const reqJpgVnIcons = require.context(
  "src/Common/Components/AppFooter/Assets/Icons/Vn",
  true,
  /\.jpg$/
);
const jpgVnFileIconsPaths = reqJpgVnIcons.keys();

// import QR from "../../../Assets/Icons/ShopeeLogo/QR.png";
import QR from "src/Components/MerchantPage/Assets/Imgs/shopeePayQr.png";
import QRVN from "../../../Assets/Icons/ShopeeLogo/QR_VN.png";
import shopeeLogo from "../../../Assets/Icons/ShopeeLogo/shopeeLogo.svg";

//images
//shopee logo
export const shopeeLogoEng = shopeeLogo;
export const shopeeLogoMy = shopeeLogo;
export const shopeeLogoVn = shopeeLogo;

//Qr code
export const QREng = [
  {
    img: QR,
    link: " https://shopee.com.my/web",
    description: "ENG QR code",
    width: "110px",
  },
];

export const QRMy = [
  {
    img: QR,
    link: "https://shopee.com.my/web",
    description: "MY QR code",
    width: "110px",
  },
];

export const QRVn = [
  {
    img: QRVN,
    link: "https://shopee.vn/download?pid=Organic&c=lp_home_qr",
    description: "VN QR code",
    width: "110px",
  },
];

//social media icons
export const socialsEng = [
  {
    img: reqSvgSocial(svgFileSocialPaths[0]),
    link: " https://www.facebook.com/ShopeePayMY ",
    description: "facebook icon",
  },
  {
    img: reqSvgSocial(svgFileSocialPaths[1]),
    link: "https://www.instagram.com/shopeepay_my/ ",
    description: "instagram icon",
  },
];

export const socialsMy = [
  {
    img: reqSvgSocial(svgFileSocialPaths[0]),
    link: " https://www.facebook.com/ShopeePayMY ",
    description: "facebook icon",
  },
  {
    img: reqSvgSocial(svgFileSocialPaths[1]),
    link: "https://www.instagram.com/shopeepay_my/ ",
    description: "instagram icon",
  },
];
export const socialsVn = [
  {
    img: reqSvgSocial(svgFileSocialPaths[0]),
    link: "https://www.facebook.com/ShopeePayVN",
    description: "facebook icon",
  },
  {
    img: reqSvgSocial(svgFileSocialPaths[3]),
    link: "https://www.youtube.com/channel/UCB8xnzqIEVG03dfeRPe0_9g",
    description: "youtube icon",
  },
];
//app stores
export const appStoresEng = [
  {
    img: reqSvgApp(svgFileAppPaths[0]),
    link: "https://apps.apple.com/my/app/id959841113",
    description: "Apple Store",
    width: "120px",
  },
  {
    img: reqSvgApp(svgFileAppPaths[1]),
    link: "https://play.google.com/store/apps/details?id=com.shopee.my&referrer=af_tranid%3DZPoWZKWO_aJuSqfj4uXOCw%26pid%3DOrganicA%26c%3Dlp_home_and1 ",
    description: "Google Play",
    width: "120px",
  },
  {
    img: reqSvgApp(svgFileAppPaths[2]),
    link: "https://appgallery.huawei.com/app/C100576469?sharePrepath=ag&channelId=web&detailType=0 ",
    description: "Hua Wei Store",
    width: "120px",
  },
];

export const appStoresMy = [
  {
    img: reqSvgApp(svgFileAppPaths[0]),
    link: " https://apps.apple.com/my/app/id959841113 ",
    description: "Apple Store",
    width: "120px",
  },
  {
    img: reqSvgApp(svgFileAppPaths[1]),
    link: "https://play.google.com/store/apps/details?id=com.shopee.my&referrer=af_tranid%3DZPoWZKWO_aJuSqfj4uXOCw%26pid%3DOrganicA%26c%3Dlp_home_and1",
    description: "Google Play",
    width: "120px",
  },
  {
    img: reqSvgApp(svgFileAppPaths[2]),
    link: "https://appgallery.huawei.com/app/C100576469?sharePrepath=ag&channelId=web&detailType=0 ",
    description: "Hua Wei Store",
    width: "120px",
  },
];
export const appStoresVn = [
  {
    img: reqSvgApp(svgFileAppPaths[0]),
    link: "https://apps.apple.com/vn/app/v%C3%AD-%C4%91i%E1%BB%87n-t%E1%BB%AD-airpay/id1032301823",
    description: "Apple Store",
    width: "120px",
  },
  {
    img: reqSvgApp(svgFileAppPaths[1]),
    link: "https://play.google.com/store/apps/details?id=com.beeasy.toppay",
    description: "Google Play",
    width: "120px",
  },
  {
    img: reqSvgApp(svgFileAppPaths[2]),
    link: "https://appgallery.huawei.com/#/app/C103042611",
    description: "Hua Wei Store",
    width: "120px",
  },
];
//bank icons
export const bankIconsEng = [
  {
    img: reqPngIcons(pngFileIconsPaths[0]),
    width: "50px",
  },
  {
    img: reqPngIcons(pngFileIconsPaths[1]),
    width: "100px",
  },
  {
    img: reqPngIcons(pngFileIconsPaths[2]),
    width: "60px",
  },
  {
    img: reqPngIcons(pngFileIconsPaths[3]),
    width: "90px",
  },
  {
    img: reqPngIcons(pngFileIconsPaths[4]),
    width: "70px",
  },
  {
    img: reqPngIcons(pngFileIconsPaths[5]),
    width: "80px",
  },
  {
    img: reqPngIcons(pngFileIconsPaths[6]),
    width: "90px",
  },
  {
    img: reqPngIcons(pngFileIconsPaths[7]),
    width: "120px",
  },
];

export const bankIconsMy = [
  {
    img: reqPngIcons(pngFileIconsPaths[0]),
    width: "50px",
  },
  {
    img: reqPngIcons(pngFileIconsPaths[1]),
    width: "100px",
  },
  {
    img: reqPngIcons(pngFileIconsPaths[2]),
    width: "60px",
  },
  {
    img: reqPngIcons(pngFileIconsPaths[3]),
    width: "90px",
  },
  {
    img: reqPngIcons(pngFileIconsPaths[4]),
    width: "70px",
  },
  {
    img: reqPngIcons(pngFileIconsPaths[5]),
    width: "80px",
  },
  {
    img: reqPngIcons(pngFileIconsPaths[6]),
    width: "90px",
  },
  {
    img: reqPngIcons(pngFileIconsPaths[7]),
    width: "120px",
  },
];

export const bankIconsVn = [
  {
    img: reqPngVnIcons(pngVnFileIconsPaths[0]),
    width: "80px",
  },
  {
    img: reqPngVnIcons(pngVnFileIconsPaths[1]),
    width: "80px",
  },
  {
    img: reqPngVnIcons(pngVnFileIconsPaths[2]),
    width: "80px",
  },
  {
    img: reqPngVnIcons(pngVnFileIconsPaths[3]),
    width: "80px",
  },
  {
    img: reqPngVnIcons(pngVnFileIconsPaths[4]),
    width: "80px",
  },
  {
    img: reqPngVnIcons(pngVnFileIconsPaths[5]),
    width: "80px",
  },
  {
    img: reqPngVnIcons(pngVnFileIconsPaths[6]),
    width: "80px",
  },
  {
    img: reqPngVnIcons(pngVnFileIconsPaths[7]),
    width: "80px",
  },
  {
    img: reqPngVnIcons(pngVnFileIconsPaths[8]),
    width: "80px",
  },
  {
    img: reqPngVnIcons(pngVnFileIconsPaths[9]),
    width: "80px",
  },
  {
    img: reqPngVnIcons(pngVnFileIconsPaths[10]),
    width: "80px",
  },
  {
    img: reqPngVnIcons(pngVnFileIconsPaths[11]),
    width: "80px",
  },
  {
    img: reqPngVnIcons(pngVnFileIconsPaths[12]),
    width: "80px",
  },
  {
    img: reqPngVnIcons(pngVnFileIconsPaths[13]),
    width: "80px",
  },
  {
    img: reqPngVnIcons(pngVnFileIconsPaths[14]),
    width: "80px",
  },
  {
    img: reqJpgVnIcons(jpgVnFileIconsPaths[0]),
    width: "80px",
  },
  {
    img: reqJpgVnIcons(jpgVnFileIconsPaths[1]),
    width: "80px",
  },
  {
    img: reqJpgVnIcons(jpgVnFileIconsPaths[2]),
    width: "80px",
  },
  {
    img: reqJpgVnIcons(jpgVnFileIconsPaths[3]),
    width: "80px",
  },
  {
    img: reqJpgVnIcons(jpgVnFileIconsPaths[4]),
    width: "80px",
  },
  {
    img: reqJpgVnIcons(jpgVnFileIconsPaths[5]),
    width: "80px",
  },
  {
    img: reqJpgVnIcons(jpgVnFileIconsPaths[6]),
    width: "80px",
  },
];

//extreme left box text
export const footerLeftTextEng = "Eng Ikuti kami";
export const footerLeftTextMy = "Ikuti kami";
export const footerLeftTextVn = "Theo dõi chúng tôi trên";
export const footerLeftTextVnEng = "Follow us";

//extreme left box download text
export const footerLeftDownloadEng = "ENG Download aplikasi Shopee sekarang!";
export const footerLeftDownloadMy = "Download aplikasi Shopee sekarang!";
export const footerLeftDownVn = "Kích hoạt Ví ShopeePay trên app Shopee ngay!";
export const footerLeftDownVnEng = "Activate ShopeePay on Shopee now!";

//right half left box title
export const footerLeftUpperTitleEng = "ENG Layanan pelanggan";
export const footerLeftUpperTitleMy = "Layanan pelanggan";
export const footerLeftUpperTitleVn = "Chăm sóc khách hàng";
export const footerLeftUpperTitleVnEng = "Services";

//right box title
export const footerRightUpperTitleEng = "ENG Jelajahi ShopeePay";
export const footerRightUpperTitleMy = "Jelajahi ShopeePay";
export const footerRightUpperTitleVn = "Khám phá ShopeePay";
export const footerRightUpperTitleVnEng = "Explore ShopeePay";

export const address =
  "Địa chỉ: Tầng 25, Tòa nhà Saigon Center 2 - Số 67 đường Lê Lợi, Phường Bến Nghé, Quận 1, Thành phố Hồ Chí Minh";
export const workingHours =
  "Giờ làm việc: 09:00 - 17:00 (trừ T7, CN và ngày lễ)";

//Left box links
export const leftBoxLinksEng = {
  shopeePayFeatures: " ShopeePay Features",
  shopeePayApp: " ShopeePay App",
  merchantFeatures: "Merchant Features",
  promo: "Promo",
  faqs: "FAQ",
  blog: "Shopee Blog",
};
export const leftBoxLinksMy = {
  shopeePayFeatures: " MYShopeePay Features",
  shopeePayApp: " MYShopeePay App",
  merchantFeatures: "MY Merchant Features",
  promo: "MY Promo",
  faqs: "MY FAQ",
  blog: "Shopee Blog",
};
export const leftBoxLinksVn = {
  shopeePayFeatures: "Tính năng ShopeePay",
  shopeePayApp: "Ứng dụng ShopeePay",
  merchantFeatures: "Đối tác ShopeePay",
  promo: "Chương trình khuyến mãi",
  faqs: "Trung tâm trợ giúp",
  blog: "Shopee Blog",
};
export const leftBoxLinksVnEng = {
  shopeePayFeatures: "ShopeePay Feature",
  shopeePayApp: "ShopeePay App",
  merchantFeatures: "Merchant Feature",
  promo: "Promotion",
  faqs: "FAQ",
  blog: "Shopee Blog",
};

//Right Box links
export const rightBoxLinksEng = {
  aboutUs: "About Us",
  termsConditions: "Terms and Conditions",
  PrivacyPolicy: "Privacy Policy",
  contactUs: "Contact Us",
};
export const rightBoxLinksMy = {
  aboutUs: "MY About Us",
  termsConditions: "MY Terms and Conditions",
  PrivacyPolicy: "MY Privacy Policy",
  contactUs: "MY Contact Us",
};

export const rightBoxLinksVn = {
  aboutUs: "Về ShopeePay",
  termsConditions: "Điều khoản sử dụng",
  PrivacyPolicy: "Chính sách bảo mật",
  contactUs: "Liên hệ với ShopeePay",
  partner: "Trở thành đối tác của ShopeePay",
  walletInfo: "Thay đổi thông tin Ví ShopeePay",
};

export const rightBoxLinksVnEng = {
  aboutUs: "About Us",
  termsConditions: "Terms and Conditions",
  PrivacyPolicy: "Privacy Policy",
  contactUs: "Contact Us",
  partner: "Become ShopeePay Partner",
  walletInfo: "Change your ShopeePay wallet’s information",
};

export const footerLowerTextMy = "METODE TOP UP";
export const footerLowerTextEng = "ENG Metode Top Up";
export const footerFootnoteEng =
  "Copyright © Shopee Limited. Trademarks belong to their respective owners. All rights reserved.";
export const footerLowerTextVn = "Phương thức nạp tiền ShopeePay";
export const footerLowerTextVnEng = "Top-up Method";
export const footerFootnoteMy = `© Shopee ${new Date().getFullYear()}. Hak Cipta Dilindungi.`;
export const footerFootnoteVn =
  "© Công ty Cổ Phần ShopeePay Giấy phép Cung ứng dịch vụ trung gian thanh toán số 25/GP-NHNN do Ngân Hàng Nhà Nước cấp ngày 22 tháng 05 năm 2023";
export const footerFootnoteTermsVn = "Điều khoản sử dụng";
export const footerFootnotePrivacyVn = "Chính sách bảo mật";

import emailIcon from "../Assets/Icons/email.svg";
import facebookIcon from "../Assets/Icons/facebook.svg";
import phoneIcon from "../Assets/Icons/phone.svg";
import userIcon from "../Assets/Icons/user.svg";
import phoneTooltipIcon from "../Assets/Icons/tooltipPhone.svg";

export const contactDetailsVnText = [
    {
        img: userIcon,
        description: "Registration page",
        content: "Thay đổi thông tin",
        link: "https://cs.shopeepay.vn/portal/webform/c2be7632b9c7401e8eb65e40e99beaf3"
    },
    {
        img: facebookIcon,
        description: "Registration page",
        content: "Tin nhắn",
        link: "https://www.facebook.com/messages/t/ShopeePayVN",
        mobileLink: "https://m.me/ShopeePayVN"
    },
    {
        img: phoneIcon,
        description: "Registration page",
        content: "dịch vụ khách hàng",
        tooltipIcon: phoneTooltipIcon,
        tooltip: "1900 6906",
        tel: "tel: 1900 6906"
    },
    {
        img: emailIcon,
        description: "Registration page",
        content: "Email",
        link: "https://help.shopeepay.vn/s/contactus"
    },
];

import { SET_REGION, SET_LANGUAGE } from "src/Common/Redux/Types";

const regionLanguageInitialState = {};

export const RegionLanguageReducer = (
  state = regionLanguageInitialState,
  action
) => {
  const { type, payload } = action;
  switch (type) {
    case SET_REGION:
      return {
        ...state,
        region: payload,
      };
    case SET_LANGUAGE:
      return {
        ...state,
        language: payload,
      };

    default:
      return state;
  }
};

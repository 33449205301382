import { lazy } from "react";
const HomePage = lazy(() => import("src/Components/HomePage"));
const NoMatchPage = lazy(() => import("src/Components/NoMatch"));
const PromoPage = lazy(() => import("src/Components/PromoPage"));
const PromoArticlePage = lazy(() => import("src/Components/PromoArticlePage"));
const PrivacyPage = lazy(() => import("src/Components/PrivacyPage"));
const PrivacyOldVersionPage = lazy(() =>
  import("src/Components/PrivacyPage/old-version")
);
const FeaturePage = lazy(() => import("src/Components/FeaturePage"));
const TermsPage = lazy(() => import("src/Components/TermsPage"));
const MerchantPage = lazy(() => import("src/Components/MerchantPage"));
const DownloadPage = lazy(() => import("src/Components/DownloadPage"));
const LinkBankPage = lazy(() => import("src/Components/LinkBankPage"));
const ContactUsPage = lazy(() => import("src/Components/ContactUsPage"));
// const FaqPage = lazy(() => import("src/Components/FAQPage"));
const RegisterSuccessPage = lazy(() =>
  import("src/Components/RegistrationSuccessPage")
);
const MerchantRegistrationPage = lazy(() =>
  import("src/Components/MerchantRegistrationPage")
);
const VerifyEmailPage = lazy(() => import("src/Components/VerifyEmailPage"));

export const NAVIGATION_ROUTES = Object.freeze({
  // Root routes
  home: {
    path: "/",
    exact: true,
    element: <HomePage />,
  },

  // feature
  feature: {
    path: "/tinh-nang",
    element: <FeaturePage />,
  },

  // merchant
  merchant: {
    path: "/doi-tac",
    element: <MerchantPage />,
  },

  merchantRegistration: {
    path: "/hop-tac-doanh-nghiep",
    element: <MerchantRegistrationPage />,
  },

  // Promo page
  Promo: {
    path: "/khuyen-mai",
    element: <PromoPage />,
  },

  PromoArticle: {
    path: "/khuyen-mai/:id",
    element: <PromoArticlePage />,
  },

  // Privacy Page
  Privacy: {
    path: "/chinh-sach-bao-mat",
    element: <PrivacyPage />,
  },

  // Privacy Old Version Page
  PrivacyOldVersion: {
    path: "/chinh-sach-bao-mat/08-2023",
    element: <PrivacyOldVersionPage />,
  },

  // Terms Page
  Terms: {
    path: "/dieu-khoan-su-dung",
    element: <TermsPage />,
  },

  //Link Bank Page
  Link: {
    path: "/lien-ket-ngan-hang",
    element: <LinkBankPage />,
  },

  LinkBank: {
    path: "/lien-ket-ngan-hang/:bankName",
    element: <LinkBankPage />,
  },

  // will have to be changed in the future
  RegisterSuccess: {
    path: "/register",
    search: "?success=true",
    element: <RegisterSuccessPage />,
  },

  // 404 - Must be the last item of the object
  noMatch: {
    path: "*",
    element: <NoMatchPage />,
  },

  // Download Page
  Download: {
    path: "/tai-ung-dung",
    element: <DownloadPage />,
  },

  // contactUs Page
  ContactUs: {
    path: "/lien-he-chung-toi",
    element: <ContactUsPage />,
  },
  // verify email page
  VerifyEmail: {
    path: "/verify",
    element: <VerifyEmailPage />,
  },

  // faqPage
  // Faq: {
  //   path: "/faqs",
  //   element: <FaqPage />
  // },
  //  // faq category Page
  //  FaqCategory: {
  //   path: "/faqs/:category/",
  //   element: <FaqPage />
  // },
  //  // faq category header Page
  // FaqHeader: {
  //   path: "/faqs/:category/#:header/",
  //   element: <FaqPage />
  // }
});

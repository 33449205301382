import { HP_TESTACTION } from '../Types';

const HomePageReducerInitialState = {};
export const HomePageReducer = (
	state = HomePageReducerInitialState,
	action
) => {
	const { type, payload } = action;
	switch (type) {
		case HP_TESTACTION: {
			return {
				...state,
				test: state.test ? state.test + payload : payload,
			};
		}
		default:
			return state;
	}
};

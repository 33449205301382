// const variables
export const feature = "tinh-nang";
export const merchant = "doi-tac";
export const linkbank = "lien-ket-ngan-hang";

export const left = "left";
export const right = "right";
export const third = "third";

// feature text
export const featureSelectionLeftEngText = "ShopeePay Feature";
export const featureSelectionRightEngText = "ShopeePay App Page";

export const featureSelectionRightVnEngText = "ShopeePay on app Shopee";

export const featureSelectionLeftVnText = "Tính năng ShopeePay";
export const featureSelectionRightVnText = "Ví ShopeePay trên app Shopee";

// merchant text
export const merchantSelectionLeftEngText = "Registration";
export const merchantSelectionRightEngText = "Merchant Features";

export const merchantSelectionLeftVnText = "Đăng ký";
export const merchantSelectionRightVnText = "Tính năng Đối tác";

/* ONLY FOR VN REGION */
//linkbank text

export const linkbankLeftVnText = "Các phương thức liên kết"
export const linkbankRightEngText = "How to link Bank"

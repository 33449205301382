import { isDesktop } from "isDesktop";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import logo from "src/Common/Assets/Icons/ShopeeLogo/shopeePayLogo2022.svg";
import logoMobile from "src/Common/Assets/Icons/ShopeeLogo/shopeePayLogoMobile2022.svg";
import LanguageToggle from "src/Common/Components/LanguageToggle";
import { setShopeeAppClicked } from "src/Common/Components/MenuSelectionBar/Redux/Actions/index.js";
import { setRegion } from "src/Common/Redux/Actions";
import { Menu, Text } from "src/Common/Theme/Typography";
import { setCurrentUrl } from "src/RootRedux/UrlRedux/Actions";
import { regionGetter } from "src/Services/RegionGetter";
import { REGIONS } from "src/Services/RegionGetter/Constants";
import { downloadVn, headerVnMenu } from "./Constants";
import "./style.less";

export const AppHeader = () => {
  const dispatch = useDispatch();
  const region = regionGetter();

  const location = useLocation();
  const { pathname } = location;

  // reducer
  const regionLanguage = useSelector((state) => state.RegionLanguageReducer);
  const { language } = regionLanguage;

  const currentUrlPageReducer = useSelector((state) => state.currentUrlReducer);
  const { currentUrl } = currentUrlPageReducer;

  // offCanvas for mobile
  const [isOpen, setIsOpen] = useState(false);

  const handleOpen = () => {
    isOpen === false ? setIsOpen(true) : setIsOpen(false);
  };

  // state for clickedMenu and header contents
  const [headerMenu, setHeaderMenu] = useState([]);
  const [download, setDownload] = useState("");

  // when app first renders, it sets the language
  const setRegionOnMount = useCallback(() => {
    dispatch(setRegion(region));
  }, [dispatch, region]);

  useEffect(() => {
    setRegionOnMount();
  }, [setRegionOnMount]);

  useEffect(() => {
    dispatch(setShopeeAppClicked(false));
    dispatch(setCurrentUrl(pathname));
    if (pathname === "/hop-tac-doanh-nghiep") {
      dispatch(setCurrentUrl("/doi-tac"));
    }
  }, [location]);

  useEffect(() => {
    dispatch(setShopeeAppClicked(false));
    switch (true) {
      case region === REGIONS.vietnam && language === "vn":
        setHeaderMenu(headerVnMenu);
        setDownload(downloadVn);
        break;
      default:
    }
  }, [language, region]);

  // for color change of header
  const onButtonClick = (path) => {
    setIsOpen(false);
    window.scrollTo(0, 0);
    dispatch(setCurrentUrl(path));
  };

  return (
    <>
      {isDesktop() ? (
        <div className="AppHeader">
          <div className="AppHeader__Contents">
            <Link to="/" onClick={() => onButtonClick("/")}>
              <img
                src={logo}
                className="AppHeader__Contents__shopeeLogo"
                alt="Shopee Logo"
              />
            </Link>
            <nav>
              {headerMenu.map((menu) => {
                const { name, path } = menu;
                const isExternalLink = path.includes("https");

                return isExternalLink ? (
                  <span
                    name={path}
                    key={path}
                    className={
                      currentUrl === path
                        ? "AppHeader__Contents__styledLink__Clicked"
                        : "AppHeader__Contents__styledLink"
                    }
                    onClick={() => window.open(path)}
                  >
                    <Menu>{name}</Menu>
                  </span>
                ) : (
                  <Link
                    key={path}
                    className={
                      currentUrl === path
                        ? "AppHeader__Contents__styledLink__Clicked"
                        : "AppHeader__Contents__styledLink"
                    }
                    to={path}
                    name={name}
                    onClick={() => onButtonClick(path)}
                  >
                    <Menu>{name}</Menu>
                  </Link>
                );
              })}
              <span className={`AppHeader__Contents__LanguageToggle`}>
                <LanguageToggle />
              </span>
              <Link
                to={"/tai-ung-dung"}
                onClick={() => onButtonClick("/tai-ung-dung")}
              >
                <button className="AppHeader__Contents__headerButton">
                  <Text
                    roboto
                    regular
                    className="AppHeader__Contents__headerButton__Text"
                  >
                    {download}
                  </Text>
                </button>
              </Link>
            </nav>
          </div>
        </div>
      ) : (
        <>
          <div className="AppHeaderMobile">
            <Link to="/" onClick={() => onButtonClick("/")}>
              <img
                src={logoMobile}
                className="AppHeaderMobile__shopeeLogo"
                alt="Shopee Logo"
              />
            </Link>
            <div>
              <input
                type="checkbox"
                className="openSidebarMenu"
                id="openSidebarMenu"
                checked={isOpen}
                onChange={handleOpen}
              />
              <label htmlFor="openSidebarMenu" className="sidebarIconToggle">
                <div className="spinner diagonal part-1" />
                <div className="spinner horizontal" />
                <div className="spinner diagonal part-2" />
              </label>

              {isOpen && (
                <div
                  className="AppHeaderMobile__menuBackground"
                  onClick={handleOpen}
                />
              )}
              <div id="sidebarMenu">
                <div className="sidebarMenuInner">
                  {headerMenu.map((menu) => {
                    const { name, path } = menu;
                    const isExternalLink = path.includes("https");

                    return isExternalLink ? (
                      <a
                        href={path}
                        className="AppHeaderMobile__itemNav"
                        key={path}
                        name={path}
                      >
                        <li
                          key={path}
                          name={path}
                          className={
                            currentUrl === path
                              ? "AppHeaderMobile__styledLink__Clicked"
                              : "AppHeaderMobile__styledLink"
                          }
                        >
                          {name}
                        </li>
                      </a>
                    ) : (
                      <Link
                        to={path}
                        key={path}
                        name={path}
                        onClick={() => onButtonClick(path)}
                      >
                        <li
                          key={path}
                          name={path}
                          className={
                            currentUrl === path
                              ? "AppHeaderMobile__styledLink__Clicked"
                              : "AppHeaderMobile__styledLink"
                          }
                        >
                          {name}
                        </li>
                      </Link>
                    );
                  })}
                </div>
                <div
                  style={{
                    display: "block",
                    margin: "auto",
                    width: "80%",
                    borderTop: "1px solid rgba(0, 0, 0, 0.09)",
                    color: "#000000",
                  }}
                />
                <div className={`AppHeaderMobile__LanguageToggle`}>
                  <LanguageToggle />
                </div>
                <a href="/tai-ung-dung">
                  <div className="AppHeaderMobile__downloadBtn">
                    <Text
                      roboto
                      medium
                      className="AppHeaderMobile__downloadBtn__Text"
                    >
                      {downloadVn}
                    </Text>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { Text } from "src/Common/Theme/Typography";
import globeIcon from "src/Common/Assets/Icons/globeIcon.svg";
import { regionGetter } from "src/Services/RegionGetter";
import { setLanguage } from "src/Common/Redux/Actions";
import { REGIONS } from "src/Services/RegionGetter/Constants";
import "./style.less";

const LanguageToggle = () => {
  const region = regionGetter();
  const capitalizedRegion = region?.toUpperCase();
  const dispatch = useDispatch();

  // state
  const [isEnglish, setIsEnglish] = useState(false);

  const [toggleLanguage, setToggleLanguage] = useState(region);

  const toggleChange = (e) => {
    setIsEnglish(!isEnglish);
  };

  useEffect(() => {
    // set as English as default since there will not be any
    if (region === REGIONS.singapore) {
      setToggleLanguage("en");
    } else if (isEnglish) {
      dispatch(setLanguage("en"));
    } else if (!isEnglish) {
      dispatch(setLanguage(region));
    }

    if (region === REGIONS.vietnam) {
      dispatch(setLanguage("vn"))
    }

  }, [dispatch, isEnglish, region, toggleLanguage]);

  return (
    <>
      {region === REGIONS.singapore && (
        <div className={"LanguageToggle"}>
          <div className={`LanguageToggle__Contents`}>
            <img src={globeIcon} alt="globeIcon" />
            <Text roboto regular>
              {capitalizedRegion}
            </Text>
            <input type="checkbox" onChange={toggleChange} />
            <div className={`LanguageToggle__Knobs__${capitalizedRegion}`} />
            <Text roboto regular>
              EN
            </Text>
          </div>
        </div>
      )}
    </>
  );
};

export default LanguageToggle;

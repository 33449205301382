import {
  SET_CURRENT_URL,
  CLICKED_FOOTER_MERCHANT_FEATURES,
  CLICKED_FOOTER_TABS_NOT_ON_HEADER,
  PROMO_ARTICLE_FETCH_DATA_REQUEST,
  PROMO_ARTICLE_FETCH_DATA_SUCCESS,
  PROMO_ARTICLE_FETCH_DATA_ERROR,
  PROMO_BANNER_FETCH_DATA_REQUEST,
  PROMO_BANNER_FETCH_DATA_SUCCESS,
  PROMO_BANNER_FETCH_DATA_ERROR,
} from "../Types";
import { GoogleSpreadsheet } from "google-spreadsheet";
import { GSHEET_DATA, GSHEET_DATA_BANNER } from "src/Common/constants";

export const setCurrentUrl = (currentUrl) => (dispatch) => {
  dispatch({
    type: SET_CURRENT_URL,
    payload: currentUrl,
  });
};

export const setClickedMerchantFeatures = (clickedMF) => (dispatch) => {
  dispatch({
    type: CLICKED_FOOTER_MERCHANT_FEATURES,
    payload: clickedMF,
  });
};

export const setClickedFooterTabsNotOnHeader =
  (clickedNotOnHeader) => (dispatch) => {
    dispatch({
      type: CLICKED_FOOTER_TABS_NOT_ON_HEADER,
      payload: clickedNotOnHeader,
    });
  };

export const getGoogleSheetData = (limit, offset) => async (dispatch) => {
  // let googleSheetData = [];

  // const doc = new GoogleSpreadsheet(REACT_APP_SPREADSHEET_ID);
  // await doc.useServiceAccountAuth({
  //   client_email: REACT_APP_CLIENT_EMAIL,
  //   private_key: REACT_APP_PRIVATE_KEY,
  // });

  // await doc.loadInfo();
  // const sheet = await doc.sheetsById[0];
  // const rows = await sheet.getRows({ limit: limit, offset: offset });

  // rows.toJson = rows.reduce((acc, row) => {
  //   const cleanRow = {};
  //   const rowEntries = Object.entries(row);
  //   rowEntries.forEach(([key, value]) => {
  //     if (key.charAt(0) !== "_") {
  //       cleanRow[key] = value;
  //     }
  //   });
  //   acc.push(cleanRow);
  //   return acc;
  // }, []);
  // googleSheetData = rows.toJson;

  let googleSheetData = GSHEET_DATA;

  localStorage.setItem("promo-article", JSON.stringify(googleSheetData));
  sessionStorage.setItem("promo-article", JSON.stringify(googleSheetData));

  try {
    dispatch({
      type: PROMO_ARTICLE_FETCH_DATA_REQUEST,
    });

    dispatch({
      type: PROMO_ARTICLE_FETCH_DATA_SUCCESS,
      payload: googleSheetData,
    });

    return googleSheetData;
  } catch (error) {
    dispatch({
      type: PROMO_ARTICLE_FETCH_DATA_ERROR,
      payload: "Error",
    });
  }
};

export const getGoogleSheetPromoBanner = () => async (dispatch) => {
  // let googleSheetData = [];

  // const doc = new GoogleSpreadsheet(REACT_APP_SPREADSHEET_ID);
  // await doc.useServiceAccountAuth({
  //   client_email: REACT_APP_CLIENT_EMAIL,
  //   private_key: REACT_APP_PRIVATE_KEY,
  // });

  // await doc.loadInfo();
  // const sheet = await doc.sheetsById[1965019905];
  // const rows = await sheet.getRows();

  // rows.toJson = rows.reduce((acc, row) => {
  //   const cleanRow = {};
  //   const rowEntries = Object.entries(row);
  //   rowEntries.forEach(([key, value]) => {
  //     if (key.charAt(0) !== "_") {
  //       cleanRow[key] = value;
  //     }
  //   });
  //   acc.push(cleanRow);
  //   return acc;
  // }, []);

  // googleSheetData = rows.toJson;

  let googleSheetData = GSHEET_DATA_BANNER;

  localStorage.setItem("promo-banner", JSON.stringify(googleSheetData));
  sessionStorage.setItem("promo-banner", JSON.stringify(googleSheetData));

  try {
    dispatch({
      type: PROMO_BANNER_FETCH_DATA_REQUEST,
    });

    dispatch({
      type: PROMO_BANNER_FETCH_DATA_SUCCESS,
      payload: googleSheetData,
    });

    return googleSheetData;
  } catch (error) {
    dispatch({
      type: PROMO_BANNER_FETCH_DATA_ERROR,
      payload: "Error",
    });
  }
};

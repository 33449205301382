import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import {
  setCurrentUrl,
  setClickedMerchantFeatures,
} from "src/RootRedux/UrlRedux/Actions";

import { setShopeeAppClicked } from "src/Common/Components/MenuSelectionBar/Redux/Actions/index.js";

import {
  shopeeLogoEng,
  shopeeLogoVn,
  QREng,
  QRVn,
  socialsEng,
  appStoresEng,
  bankIconsEng,
  bankIconsVn,
  footerLeftTextEng,
  footerLeftDownloadEng,
  footerLeftUpperTitleEng,
  footerRightUpperTitleEng,
  leftBoxLinksEng,
  leftBoxLinksVnEng,
  rightBoxLinksEng,
  footerLowerTextEng,
  footerFootnoteEng,
  footerLeftTextVn,
  footerLeftDownVn,
  footerLeftUpperTitleVn,
  footerRightUpperTitleVn,
  leftBoxLinksVn,
  rightBoxLinksVn,
  footerLowerTextVn,
  rightBoxLinksVnEng,
  footerLowerTextVnEng,
  footerFootnoteVn,
  socialsVn,
  appStoresVn,
  footerLeftTextVnEng,
  footerLeftDownVnEng,
  footerLeftUpperTitleVnEng,
  footerRightUpperTitleVnEng,
  footerFootnoteTermsVn,
  footerFootnotePrivacyVn,
  address,
  workingHours,
} from "./Constants/Constants";

import "./style.less";
import { Body1, Footnote } from "src/Common/Theme/Typography";
import { isDesktop } from "isDesktop";
import { REGIONS } from "src/Services/RegionGetter/Constants";
import { setSelectionMenu } from "../MenuSelectionBar/Redux/Actions";
import { left, right } from "../MenuSelectionBar/Constants";
import logo from "src/Common/Assets/Icons/ShopeeLogo/shopeePayLogo.svg";
import { getAppStoreLogos } from "src/Common/Services";

export const AppFooter = () => {
  const dispatch = useDispatch();
  // reducer
  const regionLanguage = useSelector((state) => state.RegionLanguageReducer);
  const { region, language } = regionLanguage;

  // state
  const [footerLeftText, setFooterLeftText] = useState("");
  const [footerLeftDownload, setFooterLeftDownload] = useState("");

  const [footerLeftUpperTitle, setFooterLeftUpperTitle] = useState("");
  const [footerRightUpperTitle, setFooterRightUpperTitle] = useState("");

  const [leftBoxLinks, setLeftBoxLinks] = useState([]);
  const [rightBoxLinks, setRightBoxLinks] = useState([]);

  const [footerLowerText, setFooterLowerText] = useState("");
  const [footerFootnote, setFooterFootnote] = useState("");
  const [footerFootnoteTerms, setFooterFootnoteTerms] = useState("");
  const [footerFootnotePrivacy, setFooternotePrivacy] = useState("");

  const [shopeeLogo, setShopeeLogo] = useState("");
  const [QR, setQR] = useState([]);
  const [socialIcons, setSocialIcons] = useState([]);
  const [bankIcons, setBankIcons] = useState([]);

  const appStoreIcons = getAppStoreLogos(appStoresVn);

  // effect
  useEffect(() => {
    switch (true) {
      case language === REGIONS.vietnam:
        setFooterLeftText(footerLeftTextVn);
        setFooterLeftDownload(footerLeftDownVn);
        setFooterLeftUpperTitle(footerLeftUpperTitleVn);
        setFooterRightUpperTitle(footerRightUpperTitleVn);
        setLeftBoxLinks(leftBoxLinksVn);
        setRightBoxLinks(rightBoxLinksVn);
        setFooterLowerText(footerLowerTextVn);
        setFooterFootnote(footerFootnoteVn);
        setShopeeLogo(shopeeLogoVn);
        setSocialIcons(socialsVn);
        setQR(QRVn);
        setBankIcons(bankIconsVn);
        setFooterFootnoteTerms(footerFootnoteTermsVn);
        setFooternotePrivacy(footerFootnotePrivacyVn);

        break;
      default:
    }
  }, [language, region]);

  // for color change of header
  const onButtonClick = (path) => {
    if (path === "/doi-tac") {
      dispatch(setCurrentUrl(path));
      dispatch(setClickedMerchantFeatures(true));
    } else if (path === "/tinh-nang/#App") {
      dispatch(setSelectionMenu(right));
    } else if (path === "/tinh-nang") {
      dispatch(setSelectionMenu(left));
    } else if (
      path === "/chinh-sach-bao-mat" ||
      path === "/dieu-khoan-su-dung" ||
      path === "/lien-he-chung-toi"
    ) {
      dispatch(setCurrentUrl(path));
      dispatch(setClickedMerchantFeatures(false));
    } else {
      dispatch(setCurrentUrl(path));
      dispatch(setClickedMerchantFeatures(false));
    }
  };

  return (
    <>
      {/* for screen size above 600px */}
      {isDesktop() ? (
        <div className="AppFooter">
          <div className="AppFooter__Wrapper">
            <div className="AppFooter__WidthContainer">
              <div className="AppFooter__LeftBox">
                <img className="AppFooter__LeftBox__logo" src={logo} alt="" />
                <div className="address">
                  <Footnote>{address}</Footnote>
                  <Footnote>{workingHours}</Footnote>
                </div>
                <div className="AppFooter__LeftBox__downloadText">
                  <Footnote>{footerLeftText}</Footnote>
                </div>

                {socialIcons.map((icon, index) => (
                  <a href={icon.link} key={index}>
                    <img
                      key={index}
                      className="AppFooter__LeftBox__socialMediaLogos"
                      src={icon.img}
                      alt={icon.description}
                    />
                  </a>
                ))}

                <div className="AppFooter__LeftBox__downloadText">
                  <Footnote>{footerLeftDownload}</Footnote>
                </div>
                <div>
                  {appStoreIcons.map((icon, index) => (
                    <a href={icon.link} key={index}>
                      <img
                        className="AppFooter__LeftBox__socialMediaLogos"
                        src={icon.img}
                        alt={icon.description}
                        width={icon.width}
                        key={index}
                      />
                    </a>
                  ))}
                </div>
                {QR.map((icon, index) => (
                  <a href={icon.link} key={index}>
                    <img
                      src={icon.img}
                      alt={icon.description}
                      className="AppFooter__LeftBox__qr"
                      width={icon.width}
                    />
                  </a>
                ))}
              </div>
              <div className="AppFooter__RightBox">
                <div className="AppFooter__RightUpperBox">
                  <div className="AppFooter__RightUpperBox__termsTitle">
                    {footerLeftUpperTitle}
                  </div>
                  <div className="AppFooter__RightUpperBox__termsTitle">
                    {footerRightUpperTitle}
                  </div>
                  <div>
                    <Body1>
                      <Link
                        to="/tinh-nang"
                        className="AppFooter__RightUpperBox__Link"
                        onClick={() => {
                          window.scrollTo({
                            top: 0,
                            left: 0,
                          });
                          onButtonClick("/tinh-nang");
                          dispatch(setShopeeAppClicked(false));
                        }}
                      >
                        {leftBoxLinks.shopeePayFeatures}
                      </Link>
                    </Body1>
                    <Body1>
                      <Link
                        to="/tinh-nang"
                        className="AppFooter__RightUpperBox__Link"
                        onClick={() => {
                          window.scrollTo({
                            top: 480,
                            left: 0,
                          });
                          onButtonClick("/tinh-nang/#App");
                          dispatch(setShopeeAppClicked(true));
                        }}
                      >
                        {leftBoxLinks.shopeePayApp}
                      </Link>
                    </Body1>
                    <Body1>
                      <Link
                        to="/doi-tac"
                        className="AppFooter__RightUpperBox__Link"
                        onClick={() => {
                          window.scrollTo({
                            top: 720,
                            left: 0,
                          });
                          onButtonClick("/doi-tac");
                          dispatch(setShopeeAppClicked(false));
                        }}
                      >
                        {leftBoxLinks.merchantFeatures}
                      </Link>
                    </Body1>
                    <Body1>
                      <Link
                        to="/khuyen-mai"
                        className="AppFooter__RightUpperBox__Link"
                        onClick={() => {
                          window.scrollTo({
                            top: 0,
                            left: 0,
                          });
                          onButtonClick("/khuyen-mai");
                          dispatch(setShopeeAppClicked(false));
                        }}
                      >
                        {leftBoxLinks.promo}
                      </Link>
                    </Body1>

                    <Body1>
                      <a
                        href="https://help.cs.shopeepay.vn/portal?source=100"
                        target="_blank"
                        className="AppFooter__RightUpperBox__Link"
                        onClick={() => {
                          window.scrollTo({
                            top: 0,
                            left: 0,
                          });
                          onButtonClick("/faqs");
                          dispatch(setShopeeAppClicked(false));
                        }} rel="noreferrer"
                      >
                        {leftBoxLinks.faqs}
                      </a>
                    </Body1>

                    <Body1>
                      <a
                        href="https://shopee.vn/blog/"
                        target="_blank"
                        className="AppFooter__RightUpperBox__Link"
                        onClick={() => {
                          window.scrollTo({
                            top: 0,
                            left: 0,
                          });
                          onButtonClick("/faqs");
                          dispatch(setShopeeAppClicked(false));
                        }} rel="noreferrer"
                      >
                        {leftBoxLinks.blog}
                      </a>
                    </Body1>
                  </div>
                  <div>
                    <Link
                      id="aboutUs"
                      to="/#about"
                      className="AppFooter__RightUpperBox__Link"
                      onClick={() => {
                        window.scrollTo({
                          top: 750,
                          left: 0,
                        });
                      }}
                    >
                      {rightBoxLinks.aboutUs}
                    </Link>
                    <Link
                      className="AppFooter__RightUpperBox__Link"
                      to="/dieu-khoan-su-dung"
                      onClick={() => {
                        window.scrollTo(0, 0);
                        onButtonClick("/dieu-khoan-su-dung");
                        dispatch(setShopeeAppClicked(false));
                      }}
                    >
                      {rightBoxLinks.termsConditions}
                    </Link>
                    <Link
                      className="AppFooter__RightUpperBox__Link"
                      to="/chinh-sach-bao-mat"
                      onClick={() => {
                        window.scrollTo(0, 0);
                        onButtonClick("/chinh-sach-bao-mat");
                        dispatch(setShopeeAppClicked(false));
                      }}
                    >
                      {rightBoxLinks.PrivacyPolicy}
                    </Link>
                    <Link
                      className="AppFooter__RightUpperBox__Link"
                      to="/lien-he-chung-toi"
                      onClick={() => {
                        window.scrollTo(0, 0);
                        onButtonClick("/lien-he-chung-toi");
                        dispatch(setShopeeAppClicked(false));
                      }}
                    >
                      {rightBoxLinks.contactUs}
                    </Link>
                    <Link
                      className="AppFooter__RightUpperBox__Link"
                      to="/hop-tac-doanh-nghiep"
                      onClick={() => {
                        window.scrollTo(0, 0);
                        onButtonClick("/hop-tac-doanh-nghiep");
                        dispatch(setShopeeAppClicked(false));
                      }}
                    >
                      {rightBoxLinks.partner}
                    </Link>
                    <a
                      href={
                        "https://cs.shopeepay.vn/portal/webform/c2be7632b9c7401e8eb65e40e99beaf3"
                      }
                      style={{ color: "#202325" }}
                    >
                      {rightBoxLinks.walletInfo}
                    </a>
                  </div>
                </div>
                <div className="AppFooter__RightLowerBox">
                  <div className="AppFooter__RightLowerBox__termsTitle">
                    {footerLowerText}
                  </div>
                  <div className="AppFooter__RightLowerBox__RightLogos">
                    {bankIcons.map((icon, index) => (
                      <div
                        className="AppFooter__RightLowerBox__RightLogos__Logo"
                        key={index}
                      >
                        <img src={icon.img} width={icon.width} alt="logo" />
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <div className="AppFooter__horizontalLine">
              <div>
                <Footnote>
                  {footerFootnote}{" "}
                  <Link
                    className={"AppFooter__horizontalLine__Footnote"}
                    to="/dieu-khoan-su-dung"
                    onClick={() => {
                      window.scrollTo(0, 0);
                      onButtonClick("/dieu-khoan-su-dung");
                      dispatch(setShopeeAppClicked(false));
                    }}
                  >
                    {"| "}
                    {footerFootnoteTerms}{" "}
                  </Link>
                  |
                  <Link
                    className={"AppFooter__horizontalLine__Footnote"}
                    to="/chinh-sach-bao-mat"
                    onClick={() => {
                      window.scrollTo(0, 0);
                      onButtonClick("/chinh-sach-bao-mat");
                      dispatch(setShopeeAppClicked(false));
                    }}
                  >
                    {" "}
                    {footerFootnotePrivacy}
                  </Link>
                </Footnote>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="AppFooterMobile">
          <div className="AppFooterMobile__TopBox">
            <img className="AppFooterMobile__TopBox__logo" src={logo} alt="" />
          </div>
          <div className="AppFooterMobile__MiddleUpperBox">
            <div className="AppFooterMobile__MiddleUpperBox__termsTitle">
              {footerLeftUpperTitle}
            </div>

            <Link
              to={{
                pathname: "/tinh-nang",
              }}
              style={{ textDecoration: "none", color: "#000000" }}
              onClick={() => {
                window.scrollTo({
                  top: 0,
                  left: 0,
                });
                onButtonClick("/tinh-nang");
                dispatch(setShopeeAppClicked(false));
              }}
            >
              <p>
                <Body1 className="AppFooterMobile__MiddleUpperBox__itemNavMobile">
                  {leftBoxLinks.shopeePayFeatures}
                </Body1>
              </p>
            </Link>

            <p>
              <Link
                to={{
                  pathname: "/tinh-nang",
                }}
                style={{ textDecoration: "none", color: "#000000" }}
                onClick={() => {
                  window.scrollTo({
                    top: 0,
                    left: 0,
                  });
                  onButtonClick("/tinh-nang/#App");
                  dispatch(setShopeeAppClicked(true));
                }}
              >
                <Body1 className="AppFooterMobile__MiddleUpperBox__itemNavMobile">
                  {leftBoxLinks.shopeePayApp}
                </Body1>
              </Link>
            </p>
            <p>
              <Link
                to="/doi-tac/#merchantApp"
                style={{ textDecoration: "none", color: "#000000" }}
                onClick={() => {
                  window.scrollTo({
                    top: 480,
                    left: 0,
                  });
                  onButtonClick("/merchant");
                  dispatch(setShopeeAppClicked(false));
                }}
              >
                <Body1 className="AppFooterMobile__MiddleUpperBox__itemNavMobile">
                  {leftBoxLinks.merchantFeatures}
                </Body1>
              </Link>
            </p>
            {region !== REGIONS.malaysia && (
              <p>
                <Link
                  to="/khuyen-mai"
                  className="itemNav"
                  onClick={() => {
                    window.scrollTo({
                      top: 480,
                      left: 0,
                    });
                    onButtonClick("/khuyen-mai");
                    dispatch(setShopeeAppClicked(false));
                  }}
                >
                  <Body1 className="AppFooterMobile__MiddleUpperBox__itemNavMobile">
                    {leftBoxLinks.promo}
                  </Body1>
                </Link>
              </p>
            )}
            <p>
              <a
                href="https://help.cs.shopeepay.vn/portal?source=100"
                className="AppFooter__RightUpperBox__Link"
                onClick={() => {
                  window.scrollTo({
                    top: 0,
                    left: 0,
                  });
                  onButtonClick("/faqs/");
                  dispatch(setShopeeAppClicked(false));
                }}
              >
                <Body1 className="AppFooterMobile__MiddleUpperBox__itemNavMobile">
                  {leftBoxLinks.faqs}
                </Body1>
              </a>
            </p>
            <p>
              <a
                href="https://shopee.vn/blog/"
                className="AppFooter__RightUpperBox__Link"
                onClick={() => {
                  window.scrollTo({
                    top: 0,
                    left: 0,
                  });
                  onButtonClick("/faqs/");
                  dispatch(setShopeeAppClicked(false));
                }}
              >
                <Body1 className="AppFooterMobile__MiddleUpperBox__itemNavMobile">
                  {leftBoxLinks.blog}
                </Body1>
              </a>
            </p>

            <div className="AppFooterMobile__MiddleUpperBox__termsTitle">
              {footerRightUpperTitle}
            </div>
            <div>
              <Link
                to="/#aboutUs"
                style={{ textDecoration: "none", color: "#000000" }}
                onClick={() => {
                  window.scrollTo({
                    top: 650,
                    left: 0,
                  });
                  onButtonClick("/");
                  dispatch(setShopeeAppClicked(false));
                }}
              >
                <p>
                  <Body1 className="AppFooterMobile__MiddleUpperBox__itemNavMobile">
                    {rightBoxLinks.aboutUs}
                  </Body1>
                </p>
              </Link>

              <p>
                <Link
                  to="/dieu-khoan-su-dung"
                  onClick={() => {
                    window.scrollTo(0, 0);
                    onButtonClick("/dieu-khoan-su-dung");
                    dispatch(setShopeeAppClicked(false));
                  }}
                >
                  <Body1 className="AppFooterMobile__MiddleUpperBox__itemNavMobile">
                    {rightBoxLinks.termsConditions}
                  </Body1>
                </Link>
              </p>
              <p>
                <Link
                  to="/chinh-sach-bao-mat"
                  onClick={() => {
                    window.scrollTo(0, 0);
                    onButtonClick("/chinh-sach-bao-mat");
                    dispatch(setShopeeAppClicked(false));
                  }}
                >
                  <Body1 className="AppFooterMobile__MiddleUpperBox__itemNavMobile">
                    {rightBoxLinks.PrivacyPolicy}
                  </Body1>
                </Link>
              </p>
              <p>
                <Link
                  to="/lien-he-chung-toi"
                  onClick={() => {
                    window.scrollTo(0, 0);
                    onButtonClick("/lien-he-chung-toi");
                    dispatch(setShopeeAppClicked(false));
                  }}
                >
                  <Body1 className="AppFooterMobile__MiddleUpperBox__itemNavMobile">
                    {rightBoxLinks.contactUs}
                  </Body1>
                </Link>
              </p>
              <p>
                <Link
                  to="/hop-tac-doanh-nghiep"
                  onClick={() => {
                    window.scrollTo(0, 0);
                    onButtonClick("/hop-tac-doanh-nghiep");
                    dispatch(setShopeeAppClicked(false));
                  }}
                >
                  <Body1 className="AppFooterMobile__MiddleUpperBox__itemNavMobile">
                    {rightBoxLinks.partner}
                  </Body1>
                </Link>
              </p>
              <a
                href=
                "https://cs.shopeepay.vn/portal/webform/c2be7632b9c7401e8eb65e40e99beaf3"
                style={{ color: "#202325" }}
              >
                <Body1 className="AppFooterMobile__MiddleUpperBox__itemNavMobile">
                  {rightBoxLinks.walletInfo}
                </Body1>
              </a>
            </div>
            <div className="AppFooterMobile__MiddleUpperBox__termsTitle__topUp">
              {footerLowerText}
            </div>
          </div>
          <div className="AppFooterMobile__MiddleLowerBox">
            <div className="AppFooterMobile__MiddleLowerBox__MiddleLogos">
              {bankIcons.map((icon, index) => (
                <div
                  className="AppFooterMobile__MiddleLowerBox__MiddleLogos__logo"
                  key={index}
                >
                  <img
                    key={index}
                    src={icon.img}
                    width={icon.width}
                    alt="Icon"
                  />
                </div>
              ))}
            </div>
            <p>{address}</p>
            <p>{workingHours}</p>
            <p>{footerLeftText}</p>

            {socialIcons.map((icon, index) => (
              <a href={icon.link} key={index}>
                <img
                  className="AppFooterMobile__MiddleLowerBox__socialMediaLogos"
                  src={icon.img}
                  alt={icon.description}
                />
              </a>
            ))}

            <p className="paddings">{footerLeftDownload}</p>
          </div>
          <div className="AppFooterMobile__BottomBox">
            <div className="AppFooterMobile__BottomBox__items">
              {appStoreIcons.map((icon, index) => (
                <a href={icon.link} key={index}>
                  <img
                    className="AppFooterMobile__MiddleLowerBox__appsLogos"
                    src={icon.img}
                    alt={icon.description}
                    width={icon.width}
                  />
                </a>
              ))}
            </div>
            <div className="AppFooterMobile__BottomBox__divider">
              <Footnote className="AppFooterMobile__BottomBox__divider__horizontalLine">
                <div>
                  {footerFootnote}{" "}
                  <div
                    className={`AppFooterMobile__BottomBox__divider__horizontalLine__Terms`}
                  >
                    <Link
                      className={
                        "AppFooterMobile__BottomBox__divider__horizontalLine__Terms__Footnote"
                      }
                      to="/dieu-khoan-su-dung"
                      onClick={() => {
                        window.scrollTo(0, 0);
                        onButtonClick("/dieu-khoan-su-dung");
                        dispatch(setShopeeAppClicked(false));
                      }}
                    >
                      {"| "}
                      {footerFootnoteTerms}{" "}
                    </Link>
                    |
                    <Link
                      className={
                        "AppFooterMobile__BottomBox__divider__horizontalLine__Terms__Footnote"
                      }
                      to="/chinh-sach-bao-mat"
                      onClick={() => {
                        window.scrollTo(0, 0);
                        onButtonClick("/chinh-sach-bao-mat");
                        dispatch(setShopeeAppClicked(false));
                      }}
                    >
                      {" "}
                      {footerFootnotePrivacy}
                    </Link>
                  </div>
                </div>
              </Footnote>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

import React, { Suspense, useLayoutEffect, useState, StrictMode } from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Navigate } from "react-router-dom";
import FontFaceObserver from "fontfaceobserver";

// Antd Styles
import "./Common/AntdStyles/antd.less";

// Styles
import "./Common/Theme/Fonts/fontLoader.less";

// App
import { AppHeader } from "src/Common/Components/AppHeader";
import { AppFooter } from "src/Common/Components/AppFooter";
import { ContactDetailsBar } from "src/Common/Components/ContactDetailsBar";

// Navigation
import { NAVIGATION_ROUTES } from "./Navigation";

// Redux
import { store } from "./RootRedux";

// =============== Pre load ===============
const LoadApp = (props) => {
  // State
  // ------------------
  const [Loaded, setLoaded] = useState(false);

  // Effects
  // ------------------
  useLayoutEffect(() => {
    (async () => {
      const promises = [];
      promises.push(loadFontAsync("Open Sans"));
      promises.push(loadFontAsync("Open Sans SemiBold"));
      promises.push(loadFontAsync("Shopee_font Bold"));
      promises.push(loadFontAsync("Shopee_font Medium"));
      promises.push(loadFontAsync("Shopee_font"));
      promises.push(loadFontAsync("Roboto Bold"));
      promises.push(loadFontAsync("Roboto Medium"));
      promises.push(loadFontAsync("Roboto Regular"));
      await Promise.all(promises);
      setLoaded(true);
    })();
  }, []);

  // Method
  // ------------------
  const loadFontAsync = (fontFamily) =>
    new Promise((res, rej) => {
      const font = new FontFaceObserver(fontFamily);
      font.load().then(res).catch(rej);
    });
  return Loaded ? props.children : null;
};

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

root.render(
  <LoadApp>
    <StrictMode>
      <Provider store={store}>
        <Router>
          <AppHeader />
          <ContactDetailsBar />
          <Suspense fallback={<div />}>
            <Routes>
              {Object.values(NAVIGATION_ROUTES).map((route, idx) => (
                <Route key={idx} {...route} />
              ))}
              <Route
                path="/contactus"
                element={<Navigate to="/lien-he-chung-toi" />}
              />
              <Route path="/gioi-thieu" element={<Navigate to="/" />} />
              <Route
                path="/hop-tac-doanh-nghiep"
                element={<Navigate to="/" />}
              />
              <Route path="/dich-vu" element={<Navigate to="/" />} />
              <Route path="/tin-tuc" element={<Navigate to="/" />} />
              <Route path="/tin-tuc/*" element={<Navigate to="/" />} />
            </Routes>
          </Suspense>
          <AppFooter />
        </Router>
      </Provider>
    </StrictMode>
  </LoadApp>
);

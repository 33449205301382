import {
  PROMO_FETCH_DATA_REQUEST,
  PROMO_FETCH_DATA_SUCCESS,
  PROMO_FETCH_DATA_ERROR,
  SET_PROMO_ID
} from "../Types";

const PromoPageReducerInitialState = {};

export const PromoPageReducer = (
  state = PromoPageReducerInitialState,
  action
) => {
  const { type, payload } = action;
  switch (type) {
    case PROMO_FETCH_DATA_REQUEST: {
      return {
        loading: true,
      };
    }
    case PROMO_FETCH_DATA_SUCCESS: {
      return {
        loading: false,
        success: true,
        promos: payload,
      };
    }
    case PROMO_FETCH_DATA_ERROR: {
      return {
        loading: false,
        success: false,
        error: payload,
      };
    }
	case SET_PROMO_ID: {
		return {
		  ...state,
		  promoId: payload,
		};
	  }
    default:
      return state;
  }
};


import { 
SET_SELECTION_MENU ,
SHOPEE_APP_CLICKED
} from "../Types";

export const setSelectionMenu = (selectedMenu) => (dispatch) => {
  dispatch({
    type: SET_SELECTION_MENU,
    payload: selectedMenu,
  });
};

export const setShopeeAppClicked = (shopeeAppClicked) => (dispatch) => {
  dispatch({
    type: SHOPEE_APP_CLICKED,
    payload: shopeeAppClicked,
  });
};
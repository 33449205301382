import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';

// CombinedReducers
import { combinedReducers } from './Reducers';

// Custom middlewares

export const store = composeWithDevTools(applyMiddleware(thunk))(createStore)(
	combinedReducers
);

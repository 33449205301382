export const REGIONS = Object.freeze({
	singapore: 'sg',
	indonesia: 'id',
	thailand: 'th',
	malaysia: 'my',
	vietnam: 'vn',
	philippines: 'ph',
});




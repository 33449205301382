import { 
  SET_CURRENT_URL ,
  CLICKED_FOOTER_MERCHANT_FEATURES ,
  CLICKED_FOOTER_TABS_NOT_ON_HEADER,
  PROMO_ARTICLE_FETCH_DATA_REQUEST,
  PROMO_ARTICLE_FETCH_DATA_SUCCESS,
  PROMO_ARTICLE_FETCH_DATA_ERROR,
  PROMO_BANNER_FETCH_DATA_REQUEST,
  PROMO_BANNER_FETCH_DATA_SUCCESS,
  PROMO_BANNER_FETCH_DATA_ERROR,
} from "../Types";

const currentUrlInitialState = {};
const currentFooterMfInitialState = {};
const currentFooterClickedInitialState = {};
const PromoArticlePageReducerInitialState = {};
const PromoBannerPageReducerInitialState = {};

export const currentUrlReducer = (
  state = currentUrlInitialState,
  action
) => {
  const { type, payload } = action;
  switch (type) {
    case SET_CURRENT_URL: {
      return {
        ...state,
        currentUrl: payload,
      };
    }
    default:
      return state;
  }
};

export const currentClickedFooterReducer = (
  state = currentFooterMfInitialState,
  action
) => {
  const { type, payload } = action;
  switch (type) {
    case CLICKED_FOOTER_MERCHANT_FEATURES : {
      return {
        ...state,
        clickedMF: payload,
      };
    }
    default:
      return state;
  }
};

export const currentFooterClickedReducer = (
  state = currentFooterClickedInitialState,
  action
) => {
  const { type, payload } = action;
  switch (type) {
    case   CLICKED_FOOTER_TABS_NOT_ON_HEADER : {
      return {
        ...state,
        clickedNotOnHeader: payload,
      };
    }
    default:
      return state;
  }
};

export const PromoArticlePageReducer = (
  state = PromoArticlePageReducerInitialState,
  action
) => {
  const { type, payload } = action;
  switch (type) {
    case PROMO_ARTICLE_FETCH_DATA_REQUEST: {
      return {
        loading: true,
      };
    }
    case PROMO_ARTICLE_FETCH_DATA_SUCCESS: {
      return {
        loading: false,
        success: true,
        promoArticle: payload,
      };
    }
    case PROMO_ARTICLE_FETCH_DATA_ERROR: {
      return {
        loading: false,
        success: false,
        error: payload,
      };
    }
    default:
      return state;
  }
};

export const PromoBannerPageReducer = (
  state = PromoBannerPageReducerInitialState,
  action
) => {
  const { type, payload } = action;
  switch (type) {
    case PROMO_BANNER_FETCH_DATA_REQUEST: {
      return {
        loading: true,
      };
    }
    case PROMO_BANNER_FETCH_DATA_SUCCESS: {
      return {
        loading: false,
        success: true,
        promoBanner: payload,
      };
    }
    case PROMO_BANNER_FETCH_DATA_ERROR: {
      return {
        loading: false,
        success: false,
        promoBanner: payload,
      };
    }
    default:
      return state;
  }
};


import { SET_SELECTION_MENU,SHOPEE_APP_CLICKED } from "../Types";

const MenuSelectionBarInitialState = {};

export const MenuSelectionBarReducer = (
  state = MenuSelectionBarInitialState,
  action
) => {
  const { type, payload } = action;
  switch (type) {
    case SET_SELECTION_MENU: {
      return {
        ...state,
        selectedMenu: payload,
      };
    }

    case SHOPEE_APP_CLICKED: {
      return {
        ...state,
        shopeeAppClicked: payload,
      };
    }
    default:
      return state;
  }
};

import { SET_SEARCH_BAR_DATA, SET_SEARCH_RESULT, SET_CLICKED,SET_PANEL } from "../Types";

const searchBarInitialState = {};
export const searchBarReducer = (
  state = searchBarInitialState,
  action
) => {
  const { type, payload } = action;
  switch (type) {
    case SET_SEARCH_BAR_DATA: {
      return {
        ...state,
        searchBar: payload,
      };
    }
    default:
      return state;
  }
};

const searchResultInitialState = {};
export const searchResultReducer = (
  state = searchResultInitialState,
  action
) => {
  const { type, payload } = action;
  switch (type) {
    case SET_SEARCH_RESULT: {
      return {
        ...state,
        searchResult: payload,
      };
    }
    default:
      return state;
  }
};

const clickedInitialState = {};
export const clickedResultReducer = (
  state = clickedInitialState,
  action
) => {
  const { type, payload } = action;
  switch (type) {
    case SET_CLICKED: {
      return {
        ...state,
        clicked: payload,
      };
    }
    default:
      return state;
  }
};


const panelClickedInitialState = {};
export const panelClickedResultReducer = (
  state = panelClickedInitialState,
  action
) => {
  const { type, payload } = action;
  switch (type) {
    case SET_PANEL: {
      return {
        ...state,
        panelClicked: payload,
      };
    }
    default:
      return state;
  }
};


import React, {useState } from "react";
import { isDesktop } from "isDesktop";
import { contactDetailsVnText } from "./Constants";
import tooltipPhone from "src/Common/Components/ContactDetailsBar/Assets/Icons/tooltipPhone.svg";
import "./style.less";

export const ContactDetailsBar = () => {
  const [isOpenMenu, setIsOpenMenu] = useState(true);
  const [showTooltip, setShowTooltip] = useState(false);

  if (window.location.pathname == "/verify") return null;

  return (
    <>
      {isDesktop() ? (
        <div className="ContactDetailsBar">
          <div className="ContactDetailsBar__Content">
            {contactDetailsVnText.map((icon, index) => (
              <React.Fragment key={index}>
                {icon.hasOwnProperty("link") ? (
                  <a href={icon.link} key={index}>
                    <img
                      src={icon.img}
                      alt={icon.description}
                      className="ContactDetailsBar__Content__Img"
                    />
                  </a>
                ) : (
                  <React.Fragment>
                    <img
                      src={icon.img}
                      key={index}
                      alt={icon.description}
                      onMouseEnter={() => setShowTooltip(true)}
                      onMouseLeave={() => setShowTooltip(false)}
                      className="ContactDetailsBar__Content__Img"
                    />
                    {showTooltip && (
                      <div className="ContactDetailsBar__Content__SidePopup">
                        <div className="ContactDetailsBar__Content__SidePopup__Inner">
                          <img
                            src={tooltipPhone}
                            className="ContactDetailsBar__Content__SidePopup__Inner__Img"
                          />
                          19006906
                        </div>
                      </div>
                    )}
                  </React.Fragment>
                )}
              </React.Fragment>
            ))}
          </div>
        </div>
      ) : (
        <div className="ContactDetailsBarMobile">
          {isOpenMenu && (
            <div className="ContactDetailsBarMobile__Wrapper">
              <div className="ContactDetailsBarMobile__Wrapper__Content">
                {contactDetailsVnText.map((icon, index) => (
                  <React.Fragment key={index}>
                    {icon.hasOwnProperty("link") ? (
                      <a href={icon.content === "Tin nhắn" ? icon.mobileLink : icon.link} key={index}>
                        <img
                          src={icon.img}
                          alt={icon.description}
                          className="ContactDetailsBarMobile__Wrapper__Content__Img"
                        />
                      </a>
                    ) : (
                      <a href={icon.tel} key={index}>
                        <img
                          src={icon.img}
                          alt={icon.description}
                          className="ContactDetailsBarMobile__Wrapper__Content__Img"
                        />
                      </a>
                    )}
                  </React.Fragment>
                ))}
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

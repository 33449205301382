import {
  SET_CHOSEN_BANK,
  SET_BANK_DATA,
  SET_BANK_LEFT_TAB,
  SET_BANK_RIGHT_TAB,
  SET_BANK_STEP
} from "../Types";



const chosenBankInitialState = {};
export const bankLinksReducer = (
  state = chosenBankInitialState,
  action
) => {
  const { type, payload } = action;
  switch (type) {
    case SET_CHOSEN_BANK: {
      return {
        ...state,
        bankLinks: payload,
      };
    }
    case SET_BANK_DATA: {
      return {
        ...state,
        bankData: payload,
      };
    }
    case SET_BANK_LEFT_TAB: {
      return {
        ...state,
        leftTabData: payload,
      };
    }

    case SET_BANK_RIGHT_TAB: {
      return {
        ...state,
        rightTabData: payload,
      };
    }
    case SET_BANK_STEP: {
      return {
        ...state,
        bankStep: payload,
      };
    }
    default:
      return state;
  }
};



import React, { useEffect, useState } from "react";

import "./style.less";

// ===== Root Text =====
export const Text = (props) => {
  const {
    className,
    bold,
    semiBold,
    medium,
    regular,
    roboto,
    shopee,
    openSans,
    ...rest
  } = props;

  // Constants
  // -------------
  const FONT_FAMILIES = Object.freeze({
    openSans: "open_sans",
    shopee: "shopee_font",
    roboto: "roboto",
  });

  const FONT_WEIGHTS = Object.freeze({
    bold: "_bold",
    semiBold: "_semi_bold",
    medium: "_medium",
    regular: "_regular",
  });

  // const FONT_COLORS = Object.freeze({
  //   white: "#ffffff",
  //   black: "#000000"
  // });

  // State
  // -------------
  const [FontFamily, setFontFamily] = useState(FONT_FAMILIES.roboto);
  const [FontWeight, setFontWeight] = useState(FONT_WEIGHTS.regular);
  // const [FontColors, setFontColors] = useState(FONT_COLORS.black);

  // Effects
  // -------------
  useEffect(() => {
    // Methods-------------
    const determineFontFamily = () => {
      Object.keys(props).forEach((key) => {
        const lowerKey = key.toLowerCase();
        if (Object.keys(FONT_FAMILIES).includes(lowerKey)) {
          if (props[key] === true) {
            setFontFamily(FONT_FAMILIES[lowerKey]);
          }
        }
      });
    };

    const determineFontWeight = () => {
      Object.keys(props).forEach((key) => {
        const lowerKey = key.toLowerCase();
        if (Object.keys(FONT_WEIGHTS).includes(lowerKey)) {
          if (props[key] === true) {
            setFontWeight(FONT_WEIGHTS[lowerKey]);
          }
        }
      });
    };
    // determine font family
    determineFontFamily();

    // determine font weight
    determineFontWeight();
  }, [FONT_FAMILIES, FONT_WEIGHTS, props]);

  return (
    <span
      className={`
				typographyRoot
				${FontFamily}${FontWeight}
				${className}
			`}
      {...rest}
    >
      {props.children}
    </span>
  );
};

// ===== Exported Typography =====
export const Headline = (props) => {
  const { className, h1, h2, ...rest } = props;

  // Constants
  // -------------
  const DEFAULT_HEADER_PREPEND = "__h";
  const DEFAULT_HEADER = "1";

  // State
  // -------------
  const [HeaderExtension, setHeaderExtension] = useState(
    DEFAULT_HEADER_PREPEND + DEFAULT_HEADER
  );

  // Effect
  // -------------
  useEffect(() => {
    let ext = DEFAULT_HEADER;
    if (h2) ext = "2";
    else if (h1) ext = "1";
    setHeaderExtension(DEFAULT_HEADER_PREPEND + ext);
  }, [h1, h2]);

  return (
    <>
      {h1 ? (
        <h1 className={`typographyHeadline${HeaderExtension} ${className}`}>
          <Text {...rest}>{props.children}</Text>
        </h1>
      ) : (
        <h2 className={`typographyHeadline${HeaderExtension} ${className}`}>
          <Text {...rest}>{props.children}</Text>
        </h2>
      )}
    </>
  );
};

export const Title = (props) => {
  const { className, ...rest } = props;

  return (
    <Text className={`typographyTitle ${className}`} {...rest}>
      {props.children}
    </Text>
  );
};

export const MenuTitle = (props) => {
  const { className, ...rest } = props;

  return (
    <Text className={`typographyTitle ${className}`} {...rest}>
      {props.children}
    </Text>
  );
};

export const Subtitle = (props) => {
  const { className, ...rest } = props;

  return (
    <Text className={`typographySubtitle ${className}`} {...rest}>
      {props.children}
    </Text>
  );
};

export const SubSubtitle = (props) => {
  const { className, ...rest } = props;

  return (
    <Text className={`typographySubSubtitle ${className}`} {...rest}>
      {props.children}
    </Text>
  );
};

export const Body1 = (props) => {
  const { className, ...rest } = props;

  return (
    <Text className={`typographyBody1 ${className}`} {...rest}>
      {props.children}
    </Text>
  );
};

export const Footnote = (props) => {
  const { className, ...rest } = props;

  return (
    <Text className={`typographyFootnote ${className}`} {...rest}>
      {props.children}
    </Text>
  );
};

export const Menu = (props) => {
  const { className, ...rest } = props;

  return (
    <Text className={`typographyMenu ${className}`} {...rest}>
      {props.children}
    </Text>
  );
};

export const Selection = (props) => {
  const { className, ...rest } = props;

  return (
    <Text className={`typographySelection ${className}`} {...rest}>
      {props.children}
    </Text>
  );
};

export const BottomBannerTitle = (props) => {
  const { className, ...rest } = props;

  return (
    <Text className={`typographyBottomBannerTitle ${className}`} {...rest}>
      {props.children}
    </Text>
  );
};

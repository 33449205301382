import { SET_REGION, SET_LANGUAGE } from "src/Common/Redux/Types";

export const setRegion = (region) => (dispatch) => {
  dispatch({
    type: SET_REGION,
    payload: region,
  });
};

export const setLanguage = (language) => (dispatch) => {
  dispatch({
    type: SET_LANGUAGE,
    payload: language,
  });
};
